<template>
  <div id="wrap">
    <!-- ヘッダー -->
    <!-- ダミーパターン（プレビュー） -->
    <template v-if="header == 'dummy'">
      <HeaderDummy></HeaderDummy>
    </template>

    <!-- エントリー情報、ウィンドウ閉じるボタン有り -->
    <template v-else-if="header == 'close'">
      <HeaderClose></HeaderClose>
    </template>

    <!-- ロゴのみ -->
    <template v-else-if="header == 'simple'">
      <SimpleHeader></SimpleHeader>
    </template>

    <!-- マガジンの時のみ -->
    <template v-else-if="header == 'magazine'">
      <HeaderMagazine></HeaderMagazine>
    </template>

    <!-- ヘッダー無し（現状はTOPのみ） -->
    <template v-else-if="header == 'none'"> </template>

    <!-- 汎用パターン（ログイン前後） -->
    <template v-else>
      <Header></Header>
    </template>

    <router-view />
    <!-- フッター -->
    <!-- パターン１（Pマークあり、トップページのみ） -->
    <template v-if="footer == 'home'">
      <footer>
        <TopPageFooter></TopPageFooter>
      </footer>
    </template>

    <!-- パターン４（メッセージチャットの時はフッターなし） -->
    <template v-else-if="footer == 'none'"> </template>

    <!-- パターン3（コピーライト以外なし、フォームページ（会員登録／エントリー／お問い合わせ／条件オファー作成／業務報告作成…全て※ヘッダーがロゴだけのページ）） -->
    <template v-else-if="footer == 'simple'">
      <footer>
        <SimpleFooter></SimpleFooter>
      </footer>
    </template>

    <!-- パターン※　マガジン詳細のみ -->
    <template v-else-if="footer == 'magazine'">
      <footer>
        <MagazineFooter></MagazineFooter>
      </footer>
    </template>
    <!-- パターン２（Pマークなし＋一部リンク追加、トップ+フォーム以外全て） -->
    <template v-else>
      <footer>
        <MynaviCompFooter></MynaviCompFooter>
      </footer>
    </template>

    <!-- モーダル表示時、背景をつける -->
    <span
      class="stage"
      :class="{ stageon: showSpModalBack && $mq == 'sp' }"
      v-show="showSpModalBack && $mq == 'sp'"
    ></span>
    <div class="nav_pagetop">
      <a @click="clickScrollTopBtn()"><span>Pagetop</span></a>
    </div>
  </div>
</template>

<style></style>

<script>
import $ from "jquery";
import Header from "@/components/partial/Header";
import HeaderClose from "@/components/partial/HeaderClose";
import HeaderDummy from "@/components/partial/HeaderDummy";
import HeaderMagazine from "@/components/partial/HeaderMagazine";
import SimpleHeader from "@/components/partial/SimpleHeader";

import TopPageFooter from "@/components/partial/TopPageFooter";
import MynaviCompFooter from "@/components/partial/MynaviCompFooter";
import SimpleFooter from "@/components/partial/SimpleFooter";
import MagazineFooter from "@/components/partial/MagazineFooter";
import PublicAPI from '@/api/public'
import Auth from './auth'
import MemberAPI from '@/api/member'

export default {
  data() {
    return {
      header: "none",
      footer: "none",
      previousHash: null,
      isChanged: null,
    };
  },
  computed: {
    showSpModalBack() {
      return this.$store.state.profile.showSpModalBack;
    },
    isPreventDefection: {
      get() {
        return this.$store.state.profile.isPreventDefection;
      },
      set(v) {
        this.$store.commit("profile/setIsPreventDefection", v);
      },
    },
    isLoggedIn() {
      return this.$store.state.auth.isLoggedIn;
    },
  },
  components: {
    Header,
    HeaderClose,
    HeaderDummy,
    HeaderMagazine,
    SimpleHeader,
    TopPageFooter,
    SimpleFooter,
    MynaviCompFooter,
    MagazineFooter,
  },
  methods: {
    fetchServer() {
      (async () => {
        try {
          const res = await fetch("/");
          if (res.status !== 200) {
            throw Error("Cannot access server.");
          }
          const html = await res.text();
          const hash = this.createHash(html);
          this.judgeHash(hash);
        } catch (e) {
          console.log(e);
        }
      })();
    },
    createHash(str) {
      const len = str.length;
      let hash = 0;
      if (len === 0) return hash;
      let i;
      for (i = 0; i < len; i++) {
        hash = (hash << 5) - hash + str.charCodeAt(i);
        hash |= 0; // Convert to 32bit integer
      }
      return hash;
    },
    judgeHash(hash) {
      if (!this.previousHash) {
        this.previousHash = hash;
        return;
      }
      if (this.previousHash !== hash) {
        this.isChanged = true;

        const result = confirm(
          "アップデートを検知しました。再読み込みを行いますか？"
        );

        if (result) {
          window.location = "/";
        }
      }
    },
    hideScroll(use) {
      if (use && this.$mq == "pc") {
        document.querySelector("html").style.overflowY = "hidden";
      } else {
        document.querySelector("html").style.overflowY = "auto";
      }
    },
    clickScrollTopBtn() {
      this.scrollTop();
    },
    sendAccessLog() {
      this.addUuuid();
      const memberId = this.$store.state.profile.memberId;
      const fullPath = this.$route.fullPath
      const path = this.$route.path
      const userAgent = window.navigator.userAgent
      const referer = window.document.referrer
      let uuuid = ''
      let cookies = document.cookie; //全てのcookieを取り出して
      let cookiesArray = cookies.split(';'); // ;で分割し配列に
      console.log(cookies, cookiesArray)

      for(var c of cookiesArray){ //一つ一つ取り出して
          var cArray = c.split('='); //さらに=で分割して配列に
          if( cArray[0] == ' _uuuid'){ // 取り出したいkeyと合致したら
              console.log(cArray);  // [key,value] 
              uuuid = cArray[1]
          }
      }
      console.log(userAgent)
      console.log(referer)
      console.log(uuuid)

      PublicAPI.postAccessLog(fullPath,path,memberId,userAgent,referer,uuuid)
    },
    addUuuid() {
      let cookie = document.cookie
      let uuuid  = ''
      console.log(cookie)
      if(!document.cookie.match('_uuuid=')){
        // uuuid生成
        let chars = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".split("");
        for (let i = 0, len = chars.length; i < len; i++) {
          switch (chars[i]) {
            case "x":
              chars[i] = Math.floor(Math.random() * 16).toString(16);
              break;
            case "y":
              chars[i] = (Math.floor(Math.random() * 4) + 8).toString(16);
              break;
          }
        }
        uuuid = chars.join("");

      } else {
        let cookiesArray = cookie.split(';'); // ;で分割し配列に

        for(var c of cookiesArray){ //一つ一つ取り出して
            var cArray = c.split('='); //さらに=で分割して配列に
            if( cArray[0] == ' _uuuid'){ // 取り出したいkeyと合致したら
                console.log(cArray);  // [key,value] 
                uuuid = cArray[1]
            }
        }
      }
      console.log(uuuid)

      // 180日間保持
      let expireDate = new Date();
      expireDate.setTime(
        expireDate.getTime() + 1000 * 3600 * 24 * 180
      );
      if(uuuid !==''){
        document.cookie = `_uuuid=${uuuid};path=/;expires=${expireDate.toUTCString()}`;
      }
      console.log(document.cookie);
    }
  },
  created() {
    // バージョンが変わったかを5分ごとに検知
    setInterval(this.fetchServer, 1000 * 60 * 5),
      // htmlのlangをjaに変更
      document.documentElement.setAttribute("lang", "ja");

    this.$store.dispatch("history/load");
    if (this.isLoggedIn) {
      this.$store.dispatch("follows/loadFromRemote");
      this.$store.dispatch("interests/loadFromRemote");
		(async () => {
			try {
				// アプリケーション上認証で退会している場合は、ログアウトする
				const { isWithdrawal } = await PublicAPI.postCheckWithdrawal(this.$store.state.profile.memberId)

				console.log(isWithdrawal)
				if( isWithdrawal === true ) {
					await Auth.logout();
					location.reload()
					console.log("withdrawal logout")
				}
			} catch (e) {
				// await Auth.logout();
				// location.reload()
				console.log("Excepetion withdrawal")
			}
		})();

    } else {
      this.$store.dispatch("follows/loadFromLocal");
      this.$store.dispatch("interests/loadFromLocal");
    }
	
  },
  watch: {
    $route(to, from) {
      console.log(this.$route);

      // ログイン済みでTOPページに行こうとしたらmypageに飛ばす
      if(to.name == "NewHome" && this.isLoggedIn ) {
        this.$router.replace('/mypage')
      }

      let body = document.getElementById('body');

      // サーバー側で指定してたらそれを使う
      // ローカルでの挙動確認はこのソースコード内のindex.htmlのbodyタグで設定する
      if( body.dataset.header ) {

        this.header = body.dataset.header;

      } else {

        // headerの切替
        switch (to.name) {
          case "JobPreview1":
          case "JobPreview2":
          case "JobPreview3":
          case "JobPreview4":
          case "JobCorporateIDPreview":
          case "MagazinePreview":
          case "GuidePreview":
            this.header = "dummy";
            break;

          case "MyPage_Works_EntryId":
            this.header = "close";
            break;

          case "JobCorporateIdJobIdEntry1":
          case "JobCorporateIdJobIdEntry1Confirm":
          case "AuthLogin":
          case "JobIdEntry2Register":
          case "JobIdEntry2Step1":
          case "JobIdEntry2Step2":
          case "JobIdEntry2Step3":
          case "JobIdEntry2Step4":
          case "JobIdEntry2Step5":
          case "JobIdEntry2Step6":
          case "CorporateIdAbuse":
          case "AuthReminderSent":
          case "AuthReminder":
          case "AuthReminderChange":
          case "SignUpInput":
          case "SignUpSent":
          case "ProfileStep1":
          case "ProfileStep2":
          case "ProfileStep3":
          case "ProfileStep4":
          case "ProfileStep5":
          case "ProfileStep6":
          case "ProfileComplete":
          case "ContactConfirm":
          case "ContactComplete":
          case "Contact":
          case "MyPage_Works_EntryId_Offer_Create":
          case "MyPage_Works_EntryId_Offer_Create2":
          case "MyPage_Works_EntryId_Offer_Create2SPRowNo":
          case "MyPage_Works_EntryId_Offer_Confirm":
          case "MyPage_Works_EntryId_Offer_OfferIdUpdate":
          case "MyPage_Works_EntryId_Offer_OfferIdUpdate2":
          case "MyPage_Works_EntryId_Offer_OfferIdUpdate2RowNo":
          case "MyPage_Works_EntryId_Offer_OfferIdConfirm":
          case "MyPage_Works_EntryId_Offer_OrderIdConfirmSP":
          case "MyPage_Works_EntryId_Report_ReportIdUpdate":
          case "MyPage_Works_EntryId_Report_ReportIdUpdateSPRowNo":
          case "MyPage_Works_EntryId_Report_ReportIdUpdateExpensesSP":
          case "Terms":
          case "Privacy1":
          case "Membership":
            this.header = "simple";
            break;

          case "Magazine_MagazineId":
            this.header = "magazine";
            break;

          case "NewHome":
            this.header = "none";
            break;

          default:
            this.header = "default";
            break;
        }
        switch (from.name) {
          case "JobPreview1":
          case "JobPreview2":
          case "JobPreview3":
          case "JobPreview4":
            // this.$store.state.company.fromPreviewFlg = true;
            this.header = "dummy";
            break;
        }
      }

      // メッセージチャットの時はhtml全体にスクロールをさせない
      switch (from.name) {
        case "MyPage_MessageChats":
        case "MyPage_MessageChats_EntryId":
          this.hideScroll(false);
          break;
      }
      switch (to.name) {
        case "MyPage_MessageChats":
        case "MyPage_MessageChats_EntryId":
          this.hideScroll(true);
          break;
      }

      if( body.dataset.footer ) {

        this.footer = body.dataset.footer;

      } else {

        switch (
          to.name // footerの切替
        ) {
          case "Home":
          case "NewHome":
            this.footer = "home";
            break;

          case "MyPage_MessageChats":
          case "MyPage_MessageChats_EntryId":
            this.footer = "none";
            break;

          case "JobCorporateIdJobIdEntry1":
          case "JobCorporateIdJobIdEntry1Confirm":
          case "CorporateIdAbuse":
          case "AuthLogin":
          case "AuthReminder":
          case "AuthReminderSent":
          case "AuthReminderChange":
          case "SignUpInput":
          case "SignUpSent":
          case "SignUpConfirmation":
          case "ProfileStep1":
            this.footer = "register";
            break;
          case "ProfileStep2":
            this.footer = "register";
            break;
          case "ProfileStep3":
            this.footer = "register";
            break;
          case "ProfileStep4":
            this.footer = "register";
            break;
          case "ProfileStep5":
            this.footer = "register";
            break;
          case "ProfileStep6":
            this.footer = "register";
            break;
          case "ProfileComplete":
          case "Contact":
          case "ContactConfirm":
          case "ContactComplete":
          case "Terms":
          case "JobIdEntry2":
          case "JobIdEntry2Register":
          case "JobIdEntry2Step1":
            this.footer = "register";
            break;
          case "JobIdEntry2Step2":
            this.footer = "register";
            break;
          case "JobIdEntry2Step3":
            this.footer = "register";
            break;
          case "JobIdEntry2Step4":
            this.footer = "register";
            break;
          case "JobIdEntry2Step5":
            this.footer = "register";
            break;
          case "JobIdEntry2Step6":
            this.footer = "register";
            break;
          case "MyPage_Works_EntryId":
          case "MyPage_Works_EntryId_Offer_Create":
          case "MyPage_Works_EntryId_Offer_Create2":
          case "MyPage_Works_EntryId_Offer_Create2SPRowNo":
          case "MyPage_Works_EntryId_Offer_Confirm":
          case "MyPage_Works_EntryId_Offer_OfferIdUpdate":
          case "MyPage_Works_EntryId_Offer_OfferIdUpdate2":
          case "MyPage_Works_EntryId_Offer_OfferIdUpdate2RowNo":
          case "MyPage_Works_EntryId_Offer_OfferIdConfirm":
          case "MyPage_Works_EntryId_Offer_OrderIdConfirmSP":
          case "MyPage_Works_EntryId_Report_ReportIdUpdate":
          case "MyPage_Works_EntryId_Report_ReportIdUpdateSPRowNo":
          case "MyPage_Works_EntryId_Report_ReportIdUpdateExpensesSP":
          case "Privacy1":
            this.footer = "simple";
            break;

          case "Magazine_MagazineId":
            this.footer = "magazine";
            break;

          default:
            this.footer = "mynaviComp";
            break;
        }

        switch (
          from.name // footerの切替
        ) {
          case "JobPreview1":
          case "JobPreview2":
          case "JobPreview3":
          case "JobPreview4":
          case "MagazinePreview":
          case "GuidePreview":
            this.footer = "simple";
            break;
        }
      }

      switch (to.name) {
        case "SignUpInput":
        case "JobIdEntry2Register":
        case "JobIdEntry2":
          this.isPreventDefection = true;
          break;
        default:
          this.isPreventDefection = false;
          break;
      }

      var urlHash = location.hash;
      if (urlHash) {
        this.$nextTick(() => {
          // ハッシュ付きのurl直打ち時にスクロールして移動
          // setTimeoutの500msで設定してる為
          // コンポーネントのロードが遅いと動作しなくなるかも
          console.log("next tick");
          $("body,html")
            .stop()
            .scrollTop(0);
          setTimeout(function() {
            var target = $(urlHash);
            var position = target.offset().top;
            $("body,html")
              .stop()
              .animate({ scrollTop: position }, 500);
          }, 500);
        });
      }

		// アクセスログ送信
		this.sendAccessLog()
    },
    isLoggedIn() {
      // MYF_2-856 ユーザー属性APIの作成
      // ログイン時にユーザー属性の変数が未定義、もしくは空の場合APIを呼び出して取得
      if( window._uaProfile == undefined || ( this.isLoggedIn && window._uaProfile.length == 0 ) ) {
        MemberAPI.getUserAttribute()
        .then((res) => {
          window._uaProfile = res;
        })
        .catch((e) => {
          console.log("function error", e);
        })
      }
    }
  },
};
</script>